import React, { useState } from 'react';
import './VoiceFrontend.css';  // Import the updated CSS file

const VoiceFrontend = ({ signOut, userEmail }) => {
  const [domain, setDomain] = useState('');
  const [jd, setJd] = useState('');
  // const [ws, setWs] = useState(null);
  const [statusMessage, setStatusMessage] = useState('Click the start button for success.');
  const [isRecognitionActive, setIsRecognitionActive] = useState(false);
  const [userInputSentStatus, setUserInputSentStatus] = useState(false);
  const [responseFromWS, setResponseFromWS] = useState('');
  const [qaList, setQaList] = useState([]);
  const [isStartButtonDisabled, setIsStartButtonDisabled] = useState(false);
  const [isStopButtonDisabled, setIsStopButtonDisabled] = useState(true);
  const [hasResponse, setHasResponse] = useState(false); // New state variable
  const [isLoading, setIsLoading] = useState(false); // Loading state
  const [isUserValidated, setIsUserValidated] = useState(false); // User validation state

  const validateUser = async () => {
    setIsLoading(true);
    console.log("User Email:", userEmail);
    try {
      // const response = await fetch('http://127.0.0.1:8000/validate_user', {
        const response = await fetch('https://interview-assist-3ai.com/validate_user', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: userEmail })
      });

      if (!response.ok) {
        throw new Error('Access denied');
      }
      setIsUserValidated(true);
      setStatusMessage('User validated. You can now use speech recognition.');
      handleStart(); // Start speech recognition after validation
    } catch (error) {
      setStatusMessage(error.message);
    } finally {
      setIsLoading(false);
    }
  };


  const startRecognition = (socket) => {
    if ('webkitSpeechRecognition' in window) {
      const recognition = new window.webkitSpeechRecognition();
      console.log(recognition, 'recognition:');
      recognition.continuous = true;
      recognition.interimResults = false;

      recognition.onstart = () => {
        const timestamp = new Date().toLocaleString();
        console.log('started', timestamp);
        setIsRecognitionActive(true);
        setStatusMessage('Speech recognition is active...');
      };

      recognition.onresult = (event) => {
        let finalTranscript = '';

        for (let i = event.resultIndex; i < event.results.length; ++i) {
          if (event.results[i].isFinal) {
            finalTranscript += event.results[i][0].transcript;
            
          }
        }
        console.log('finalTranscript : ',finalTranscript)   

        if (socket && socket.readyState === WebSocket.OPEN) {
          const finalDict = userInputSentStatus
            ? { 'question': finalTranscript }
            : { 'question': finalTranscript, 'domain': domain, 'jd': jd };

          setUserInputSentStatus(true);
          socket.send(JSON.stringify(finalDict));
        } else {
          console.error('WebSocket is not open.');
        }
      };

      recognition.onerror = (event) => {
        setIsRecognitionActive(false);
        // clearTimeout(recognitionTimeout);
      };

      recognition.onend = () => {
        setIsRecognitionActive(false);
        setStatusMessage('Speech recognition stopped.');
        const timestamp = new Date().toLocaleString();
        console.log('stopped', timestamp);
        recognition.start();
        setIsStopButtonDisabled(true);
        setIsStartButtonDisabled(false);
      };

      recognition.start();
    } else {
      setStatusMessage('Your browser does not support Web Speech API.');
    }
  };

  const displayText = (text) => {
    const textArray = text.split('$');
    const qaItems = textArray.map((item, index) => ({
      label: index % 2 === 0 ? 'Question' : 'Answer',
      text: item.trim(),
    }));
    console.log(qaItems)
    setQaList(qaItems);
  };

  const handleStart = () => {

  // const handleStart = async () => {
  //   if (!isUserValidated) {
  //     await validateUser();
  //     return;
  //   }  
    // const socket = new WebSocket('ws://127.0.0.1:8000/ws');
    const socket = new WebSocket('wss://interview-assist-3ai.com/ws');
    socket.onopen = () => {
      console.log('WebSocket connection opened.');
      console.log('socket:', socket)
      startRecognition(socket);
      setIsStartButtonDisabled(true);
      setIsStopButtonDisabled(false);
    };

    socket.onmessage = (event) => {
      setResponseFromWS(event.data);
      setHasResponse(true);
      displayText(event.data);
    };

    socket.onclose = () => {
      console.log('WebSocket connection closed.');
      setStatusMessage('WebSocket closed.');
    };

    socket.onerror = (error) => {
      console.error('WebSocket error:', error);
      setStatusMessage('WebSocket error occurred.');
    };
  };

  const handleStop = () => {
      setIsRecognitionActive(false);
      setIsStopButtonDisabled(true);
      setIsStartButtonDisabled(false);
      setStatusMessage('Stopped speech recognition.');  
  };

  return (
    <div >
      <nav className="navbar">
        <div className="navdiv">
          <div className="logo">
            <a href="/">Interview Assist</a>
          </div>
          <ul>
            <li>
              <input
                type="text"
                id="jd"
                placeholder="Enter job description"
                value={jd}
                onChange={(e) => setJd(e.target.value)}
              />
            </li>
            <li>
              <select
                id="domain"
                value={domain}
                onChange={(e) => setDomain(e.target.value)}
              >
                <option value="">-- Select Domain --</option>
                <option value="Data_Science">AI/ML</option>
                <option value="SAP">SAP</option>
                <option value="Python">Python</option>
                <option value="Java">Java</option>
              </select>
            </li>
            <li>
              <button id="startButton" onClick={validateUser} disabled={isStartButtonDisabled}>Start</button>
              <button id="stopButton" onClick={handleStop} disabled={isStopButtonDisabled}>Stop</button>
              <button id="signoutButton" onClick={signOut}>Signout</button>
            </li>
          </ul>
        </div>
        <p id="status">{statusMessage}</p>
      </nav>
      {hasResponse && ( // Conditionally render qa-box
        <div className="qa-box">
          <ul>
            {qaList.map((item, index) => (
              <li key={index}>
                <strong>{item.label}: </strong>
                <pre>{item.text}</pre>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default VoiceFrontend;
