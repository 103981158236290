import React from 'react';
import './App.css';
import { Amplify } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import awsExports from './aws-exports';
import VoiceFrontend from './VoiceFrontend'; // Import the new VoiceComponent

Amplify.configure(awsExports);

function App() {
    return (
        <div className="App">
            <Authenticator>
                {({ signOut, user }) => (
                    <main>
                        <header className='App-header'>
                            <VoiceFrontend signOut={signOut} userEmail={user.signInDetails.loginId}/>
                        </header>
                    </main>
                )}
            </Authenticator>
        </div>
    );
}

export default App;